<template>
  <section class="pb-60">
    <h3 class="heading-3">
      {{
        i18n(translations.title, {
          device: title,
        })
      }}
    </h3>
    <RevCard class="mt-24 flex flex-col flex-nowrap items-center p-16">
      <h3>
        <span aria-hidden="true" role="img"> 🎉 </span>

        {{ price }}
      </h3>
      <span class="body-2">
        {{ i18n(translations.priceHint) }}
      </span>
    </RevCard>
    <RevList class="my-16" :has-external-borders="false">
      <RevListItemStatic>
        <template #prefix>
          <IconShoppingBag />
        </template>
        <template #label>
          {{ i18n(translations.firstItemTitle) }}
        </template>
        <template #description>
          {{ i18n(translations.firstItemDesc) }}
        </template>
      </RevListItemStatic>
      <RevListItemStatic>
        <template #prefix>
          <IconPackage />
        </template>
        <template #label>
          {{ i18n(translations.secondItemTitle) }}
        </template>
        <template #description>
          {{ i18n(translations.secondItemDesc) }}
        </template>
      </RevListItemStatic>
      <RevListItemStatic>
        <template #prefix>
          <IconMoney />
        </template>
        <template #label>
          {{ i18n(translations.thirdItemTitle) }}
        </template>
        <template #description>
          {{ i18n(translations.thirdItemDesc) }}
        </template>
      </RevListItemStatic>
    </RevList>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconPackage } from '@ds/icons/IconPackage'
import { IconShoppingBag } from '@ds/icons/IconShoppingBag'

import translations from './Offer.translations'

const i18n = useI18n()

const props = defineProps<{ offer: GetOfferV1Response }>()

const title = computed(() => {
  return props.offer?.product.fullTitle
})

const price = computed(() => {
  return i18n.price(props.offer?.listing.price)
})
</script>
