export default {
  title: {
    id: 'buyback_swap_no_offer_title',
    defaultMessage: 'Oh poo...!',
  },
  contentFirst: {
    id: 'buyback_swap_no_offer_content_1',
    defaultMessage:
      'There are no offers for your item today. It might be because your item is too damaged or because there isn’t any demand right now.',
  },
  contentSecond: {
    id: 'buyback_swap_no_offer_content_2',
    defaultMessage:
      'Try again another time — BuyBack is a new program and demand is rising daily!',
  },
  buttonSell: {
    id: 'buyback_swap_no_offer_sell',
    defaultMessage: 'Sell something else',
  },
}
