import { useAsyncData } from '#imports'
import { computed, watchEffect } from 'vue'

import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import type { PopularSearch } from '~/api/buyback-funnel/types'

import { SWAP_INFO_MESSAGE } from '../swap/components/SwapModal/constants'

import type { BuybackProduct } from './useBuybackSearch'

function popularSearchToBuybackPopularSearch(
  hit: PopularSearch,
): BuybackProduct {
  return {
    model: hit.model,
    brand: hit.brand,
    category: hit.category,
    id: hit.productId,
    price: hit.priceEstimate,
    productLine: hit.productLine,
  }
}

function getPopularSearches() {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('buyback-popular-searches')

  return useAsyncData(key, () => {
    return $httpFetch('/buyback-funnel/api/v1/search/popular/:search', {
      pathParams: {
        search: 'descending-price-search',
      },
    })
  })
}

export function useBuybackPopularSearches() {
  const logger = useLogger()
  const { data: popularSearchesPayload, error } = getPopularSearches()

  const popularSearches = computed(() => {
    return (
      popularSearchesPayload.value?.map(popularSearchToBuybackPopularSearch) ??
      []
    )
  })

  watchEffect(() => {
    if (error.value) {
      logger.error(SWAP_INFO_MESSAGE.SWAP_POPULAR, {
        error: error.value,
        owners: ['bot-squad-circularity-customer-front'],
      })
    }
  })

  return { popularSearches }
}
