<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    data-test="modal-swap"
    :name="props.modalName"
    :title="i18n(translations.titleModal)"
    @close="handleClose"
    @open="handleOpen"
  >
    <template #body>
      <Intro
        v-if="displayIntro"
        :close-wording
        :price="props.price"
        :variant="intro"
        @close="handleCloseModal"
        @next-step="getCategories"
      />

      <template v-if="displayFunnel || displayOffer || displayCategories">
        <div class="text-static-brand-mid mb-16 flex items-center">
          <RevButtonBase @click="handleBack">
            <IconArrowLeft
              v-if="
                displayFunnel || displayOffer || (displayCategories && intro)
              "
              :aria-label="i18n(modalBuybackTranslations.backButtonLabel)"
              size="medium"
            />
          </RevButtonBase>
          <h2 class="body-1 m-auto">{{ title }}</h2>
        </div>
        <Categories
          v-if="displayCategories"
          :categories
          class="mt-7"
          variant="embedded"
          @next-step="getCategoryFunnel"
        />
        <div v-if="displayFunnel" ref="container">
          <QuestionsForm
            :active-step="activeQuestionsStep"
            :auto-next="showFunnelButton ? false : true"
            class="mb-72"
            :funnel
            :has-submit-button="false"
            :isLoadingOffer="isPostingAnswers"
            variant="embedded"
            @next-question="getNextQuestion"
            @next-step="getNextStep"
            @submit-answers="handlePostAnswers"
          />

          <div
            v-if="showFunnelButton"
            class="bg-surface-default-mid absolute bottom-0 left-0 right-0 m-24"
          >
            <RevButton
              :form="QUESTIONS_FORM_ID"
              full-width="always"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.continue) }}
            </RevButton>
          </div>
        </div>

        <OfferStep
          v-if="displayOffer"
          :close-wording
          :model
          :name="offer?.product.shortTitle"
          :price="offer?.listing.price"
          :tier="offer?.tier"
          @close="handleCloseModal"
          @remove="handleRemove"
          @sell-other="goToCategories"
        />
      </template>
      <div v-if="displayLoader" class="mt-6 flex items-center justify-center">
        <RevLoadingScreen :text="loadingText" />
      </div>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch, watchEffect } from 'vue'
import { type LocationQuery } from 'vue-router'

import {
  type GetOfferV1Response,
  getOfferV1,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import {
  type FunnelStep,
  type GetQuestionsResponse,
  type Question,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { $httpFetch as $httpFetchv1 } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { hashObjects } from '@backmarket/utils/object/hashObjects'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevDrawer } from '@ds/components/Drawer'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'
import { closeModal } from '@ds/components/ModalBase'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import QuestionsForm, {
  type NextQuestionPayload,
  type NextStepPayload,
  type SubmitAnswersPayload,
} from '~/scopes/buyback/components/QuestionsForm/QuestionsForm.vue'
import { QUESTIONS_FORM_ID } from '~/scopes/buyback/components/QuestionsForm/constants'
import type { ErrorData } from '~/scopes/buyback/components/TheCatcher/useCatcher'
import Categories from '~/scopes/buyback/swap/components/Categories/Categories.vue'
import Intro from '~/scopes/buyback/swap/components/Intro/Intro.vue'
import OfferStep from '~/scopes/buyback/swap/components/OfferStep/OfferStep.vue'
import { useSwapModalStore } from '~/scopes/buyback/swap/stores/useSwapModalStore'
import { CHECKOUT } from '~/scopes/checkout/routes-names'

import { STEPS, SWAP_INFO_MESSAGE } from '../SwapModal/constants'
import type { SwapModalProps } from '../SwapModalWrapper/SwapModalWrapper.vue'

import translations from './EmbeddedSwapModal.translations'

type Steps = (typeof STEPS)[keyof typeof STEPS]
const i18n = useI18n()
const logger = useLogger()

const { trackClick, trackSwapOffer } = useTracking()
const $httpFetch = useHttpFetch()

const { setShowAddToCartModal } = useSwapModalStore()
const { saveBuybackOffer, hasBuybackOffer, deleteBuybackOffer } =
  useBuybackOffer()

const emit = defineEmits(['confirmation', 'continue', 'close-with-offer'])

const props = withDefaults(defineProps<SwapModalProps>(), {
  redirectOnCloseTarget: null,
  initialPayload: undefined,
  intro: undefined,
  price: '',
})

const getInitialStep = () => {
  if (!isEmpty(props.initialPayload)) {
    return STEPS.QUESTIONS
  }
  if (!props.intro) {
    return STEPS.CATEGORIES
  }

  return STEPS.INTRO
}

const step = ref<Exclude<Steps, 'no_offer'>>(getInitialStep())
const history = ref<
  Record<string, GetQuestionsResponse> | Record<string, null>
>({})
const funnel = ref<Array<FunnelStep>>([])
const categories = ref<Question | Record<string, never>>({})
const showFunnelButton = ref(false)
const scrollToTop = ref(false)
const container = ref<HTMLElement | null>(null)
const isLoadingCategories = ref(false)
const isLoadingInitialPayload = ref(false)
const isPostingAnswers = ref(false)
const isOpen = ref(false)
const form = ref<LocationQuery>({})
const offer = ref<GetOfferV1Response>()
const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = {
  message: SWAP_INFO_MESSAGE.SWAP_EMBEDDED_MODAL,
  step: step.value,
}

const model = computed(() => {
  if (!form.value?.model || Array.isArray(form.value.model)) {
    return ''
  }

  return form.value.model
})

const activeQuestionsStep = computed(() => {
  const steps = funnel.value.map(
    ({ step: questionFunnelStep }) => questionFunnelStep,
  )

  return steps.find((questionFunnelStep) => questionFunnelStep?.active) || null
})

const titles = computed(() => {
  return {
    [STEPS.INTRO]: i18n(translations.categoriesTitle),
    [STEPS.CATEGORIES]: i18n(translations.categoriesTitle),
    [STEPS.QUESTIONS]: activeQuestionsStep.value?.label ?? '',
    [STEPS.OFFER]: i18n(translations.offerTitle),
  }
})

const title = computed(() => {
  return titles.value[step.value]
})

const closeWording = computed(() => {
  if (props.redirectOnCloseTarget !== null) {
    return i18n(translations.noToCart)
  }

  return i18n(translations.continue)
})

const displayLoader = computed(() => {
  return (
    isLoadingCategories.value ||
    isPostingAnswers.value ||
    isLoadingInitialPayload.value
  )
})

const loadingText = computed(() => {
  return isPostingAnswers.value ? i18n(translations.loadingOfferText) : ''
})

const displayIntro = computed(() => {
  return step.value === STEPS.INTRO && !isLoadingCategories.value
})

const displayCategories = computed(() => {
  return step.value === STEPS.CATEGORIES && !isLoadingCategories.value
})

const displayFunnel = computed(() => {
  return (
    step.value === STEPS.QUESTIONS &&
    !isPostingAnswers.value &&
    !isLoadingCategories.value
  )
})

const displayOffer = computed(() => {
  return step.value === STEPS.OFFER
})

function handleTracking(label: string) {
  // amplitude events
  if (label === 'swap_offer') {
    trackSwapOffer({
      category: props.datalayerCategory,
      ...form.value,
      // eslint-disable-next-line camelcase
      swap_estimation: i18n.price(offer.value?.listing.price || ''),
      zone: props.zone,
    })
  } else {
    trackClick({
      value: {
        category: props.datalayerCategory,
        ...form.value,
        // eslint-disable-next-line camelcase
        swap_estimation: i18n.price(offer.value?.listing.price || ''),
      },
      name: label,
      zone: props.zone,
    })
  }
}

async function getQuestions({
  formPayload = {},
}:
  | { formPayload: LocationQuery }
  | Record<string, never> = {}): Promise<GetQuestionsResponse | null> {
  const id = hashObjects(formPayload)
  const cachedResponse = history.value[id]

  if (cachedResponse) {
    return cachedResponse
  }

  const questionsResponse = await $httpFetch(
    '/buyback-funnel/api/v1/funnel/:kind/questions',
    {
      pathParams: {
        kind: 'swap',
      },
      query: {
        ...formPayload,
        embedded: 'true',
      },
    },
  )

  if (isEmpty(questionsResponse)) {
    logger.error(SWAP_INFO_MESSAGE.EMPTY_QUESTIONS, {
      error: new Error('Empty response from questions API'),
      info: { ...formPayload },
      owners: ['bot-squad-circularity-customer-front'],
    })
  }

  history.value[id] = questionsResponse

  return questionsResponse
}

async function fetchQuestions({ nextStep = 'true', formPayload = {} }) {
  try {
    form.value = {
      ...form.value,
      ...formPayload,
      nextStep,
    }

    const payload = await getQuestions({
      formPayload: form.value,
    })
    funnel.value = payload?.funnel || []
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

async function getNextQuestion({
  formPayload = {},
  reset = [],
}: NextQuestionPayload): Promise<void> {
  if (reset.length) {
    reset.forEach((key) => {
      delete form.value[key]
    })
  }
  await fetchQuestions({
    formPayload,
    nextStep: showFunnelButton.value ? 'false' : 'true',
  })
}

async function getNextStep({
  formPayload,
  shouldScrollToTop,
}: NextStepPayload) {
  showFunnelButton.value = false
  scrollToTop.value = shouldScrollToTop

  await fetchQuestions({
    formPayload,
    nextStep: 'true',
  })

  if (scrollToTop.value && container.value) {
    container.value.scrollIntoView(true)
    scrollToTop.value = false
  }
}

function handleCloseModal() {
  closeModal(props.modalName)
}

async function handlePostAnswers({ formPayload }: SubmitAnswersPayload) {
  form.value = {
    ...form.value,
    ...formPayload,
  }
  isPostingAnswers.value = true

  // Wait 800 before fetching the offer in order to
  // give customers the feeling that we are 'really' searching
  // (meanwhile a screen loader with additional information is shown)
  await new Promise((resolve) => {
    setTimeout(resolve, 800)
  })

  try {
    offer.value = await $httpFetchv1(getOfferV1, {
      queryParams: {
        ...form.value,
      },
      pathParams: {
        kind: 'swap',
      },
    })
  } catch (error) {
    const httpError = error as HttpApiError

    if (httpError.status === 404) {
      handleTracking('scarabee')
    } else {
      logger.error(SWAP_INFO_MESSAGE.GET_OFFER, {
        httpError,
        owners: ['bot-squad-circularity-customer-front'],
      })
    }
  } finally {
    isPostingAnswers.value = false
    step.value = STEPS.OFFER
  }

  // Auto accepting the trade-in offer
  try {
    if (offer.value) {
      await saveBuybackOffer(offer.value, form.value)
      setShowAddToCartModal(false)

      handleTracking('swap_offer')
      emit('confirmation')
    }
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.ADD_SWAP, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

function resetFunnel(): void {
  funnel.value = []
  form.value = {}
  offer.value = undefined
  categories.value = {}
  step.value = STEPS.INTRO
  showFunnelButton.value = false
}

function handleClose() {
  // dismiss starting the funnel from 'add to cart'
  if (step.value === STEPS.INTRO && props.intro === 'atc') {
    handleTracking('go_to_cart')
  } else {
    handleTracking(
      hasBuybackOffer.value
        ? 'close_swap_offer_modal'
        : 'close_swap_funnel_modal',
    )
  }

  isOpen.value = false
  resetFunnel()

  if (props.redirectOnCloseTarget === CHECKOUT.CART) {
    emit('continue')
  }
}

async function getCategories(): Promise<void> {
  handleTracking('buyback_estimate')

  isLoadingCategories.value = true

  try {
    const payload = await getQuestions()

    const firstStepQuestion = payload?.funnel || []

    categories.value = firstStepQuestion[0].questions[0]
    step.value = STEPS.CATEGORIES
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_CATEGORIES, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  } finally {
    isLoadingCategories.value = false
  }
}

async function getCategoryFunnel(category: { category: string }) {
  try {
    form.value = {
      ...category,
    }

    handleTracking('category')

    const payload = await getQuestions({
      formPayload: form.value,
    })

    funnel.value = payload?.funnel || []
    step.value = STEPS.QUESTIONS
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

// TODO: can be integrated into getCategories
async function goToCategories() {
  if (isEmpty(categories.value)) {
    await getCategories()
  }
  step.value = STEPS.CATEGORIES
}

async function getFunnelStepFromHistory(): Promise<void> {
  const isFirstQuestionStep =
    funnel.value.findIndex(
      ({ step: questionFunnelStep }) => questionFunnelStep?.active,
    ) === 0

  if (isFirstQuestionStep) {
    showFunnelButton.value = false
    await goToCategories()

    return
  }

  try {
    const activeFunnelQuestionsStep = funnel.value.find(
      ({ step: questionFunnelStep }) => questionFunnelStep?.active,
    )
    const keysToDelete = activeFunnelQuestionsStep?.questions.map(
      (question) => question.key,
    )

    keysToDelete?.forEach((key) => {
      delete form.value[key]
    })

    form.value = {
      ...form.value,
      nextStep: 'false',
    }

    const payload = await getQuestions({ formPayload: form.value })
    funnel.value = payload?.funnel || []
  } catch (err) {
    const error = err as Error
    logger.error(SWAP_INFO_MESSAGE.GET_QUESTIONS, {
      error,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
}

async function handleBack(): Promise<void> {
  showFunnelButton.value = true
  handleTracking('funnel_arrow_back')

  if (step.value === STEPS.CATEGORIES) {
    step.value = STEPS.INTRO
  }

  if (step.value === STEPS.QUESTIONS) {
    await getFunnelStepFromHistory()
  }

  if (step.value === STEPS.OFFER) {
    step.value = STEPS.QUESTIONS
  }
}

function handleOpen() {
  isOpen.value = true
  handleTracking('swap')
}

function handleRemove() {
  handleTracking('remove_swap')
  deleteBuybackOffer()
  handleCloseModal()
}

watch(
  [() => props.initialPayload, () => props.intro, isOpen],
  async ([newInitialPayload, newIntro, newIsOpen]) => {
    if (newIsOpen) {
      step.value = getInitialStep()
      if (!isEmpty(newInitialPayload)) {
        isLoadingInitialPayload.value = true
        await getNextQuestion({ formPayload: newInitialPayload })
        isLoadingInitialPayload.value = false

        return
      }

      if (!newIntro) {
        await getCategories()
      }
    }
  },
  { immediate: true },
)

watch(form, (newForm) => {
  if (newForm && activeQuestionsStep.value && !showFunnelButton.value) {
    handleTracking(activeQuestionsStep.value.key)
  }
})

watchEffect(() => {
  if (step.value && isOpen.value) {
    logger.info(SWAP_INFO_MESSAGE.VIEW_SWAP_STEP, {
      step: step.value,
      owners: ['bot-squad-circularity-customer-front'],
    })
  }
})
</script>
