<template>
  <RevCard class="!bg-surface-brand-hi flex flex-col p-16">
    <section>
      <header class="mb-8 flex items-start justify-between">
        <p class="body-2 flex items-center gap-8">
          <IconCheckInCircleFilled class="text-static-brand-mid" />
          <span>{{ name }}</span>
        </p>
        <RevButtonBase class="body-2" @click="$emit('remove')">
          {{ i18n(translations.delete) }}
        </RevButtonBase>
      </header>

      <div class="mb-16 flex flex-col gap-4">
        <h2
          v-if="!tier || tier === 'low' || tier === 'medium'"
          class="body-1-bold"
        >
          {{ i18n(translations.titleLower) }}
        </h2>
        <h2 v-if="tier === 'high' || tier === 'great'" class="body-1-bold">
          {{ i18n(translations.titleUpper) }}
        </h2>

        <div class="flex flex-col">
          <span class="text-static-brand-mid heading-1">
            {{ i18n.price(price) }}
          </span>
          <span class="text-static-brand-mid body-2">
            {{ i18n(translations.tradeinValue) }}
          </span>
        </div>
      </div>

      <ul class="body-2 flex list-none flex-col gap-4">
        <li v-if="tier === 'medium'" class="flex items-start gap-8">
          <IconSparkles class="mt-4 shrink-0" size="small" />
          <span> {{ i18n(translations.valueDecrease) }} </span>
        </li>
        <li
          v-if="tier === 'low' || tier === 'medium'"
          class="flex items-start gap-8"
        >
          <IconLeaf class="mt-4 shrink-0" size="small" />
          <span> {{ i18n(translations.circularity) }} </span>
        </li>
        <li
          v-if="tier === 'high' || tier === 'great'"
          class="flex items-start gap-8"
        >
          <IconMoney class="mt-4 shrink-0" size="small" />
          <span> {{ i18n(translations.money) }} </span>
        </li>
        <li class="flex items-start gap-8">
          <IconTruckExpress class="shrink-0" size="small" />
          <span> {{ i18n(translations.shipping) }} </span>
        </li>
        <li
          v-if="tier === 'high' || tier === 'great'"
          class="flex items-start gap-8"
        >
          <IconQuality class="mt-4 shrink-0" size="small" />
          <span> {{ i18n(translations.quality) }} </span>
        </li>
      </ul>
    </section>
  </RevCard>
</template>

<script lang="ts" setup>
import type { Price } from '@backmarket/http-api'
import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { IconCheckInCircleFilled } from '@ds/icons/IconCheckInCircleFilled'
import { IconLeaf } from '@ds/icons/IconLeaf'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import translations from './AcceptedOffer.translations'

defineProps<{
  name: string
  price: Price
  tier?: GetOfferV1Response['tier']
}>()

defineEmits(['remove'])

const i18n = useI18n()
</script>
