import { useAsyncData, useRuntimeConfig } from '#imports'
import { ref, watchEffect } from 'vue'

import { type Price } from '@backmarket/http-api'
import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useDebounceFn } from '@vueuse/core'
import algoliasearch, { type SearchIndex } from 'algoliasearch'

import type { Scope } from '~/api/buyback-funnel/types'

import { SWAP_INFO_MESSAGE } from '../swap/components/SwapModal/constants'

interface AlgoliaHit {
  maxPriceEstimate: Price
  productLine: string
  objectID: string
  storage: string
  model: string
  brand: string
  category: string
  image: string
}

export interface BuybackProduct {
  model: string
  brand: string
  category: string
  id: string
  price: Price
  productLine: string
}

function algoliaHitToBuybackProduct(hit: AlgoliaHit): BuybackProduct {
  return {
    model: hit.model,
    brand: hit.brand,
    category: hit.category,
    id: hit.objectID,
    price: hit.maxPriceEstimate,
    productLine: hit.productLine,
  }
}

function getBuybackSearchConfiguartion(scope: Scope) {
  const $httpFetch = useHttpFetch()
  const key = getAsyncDataKey('buyback-search-configuration')

  return useAsyncData(key, () => {
    return $httpFetch('/buyback-funnel/api/v1/search/config/:scope', {
      pathParams: { scope },
    })
  })
}

export function useBuybackSearch(
  scope: Scope,
  options = { debounce: false, debounceTime: 0 },
) {
  const query = ref('')
  const apiKey = ref<string>()
  const indexName = ref<string>()
  const algoliaIndex = ref<SearchIndex | null>(null)
  const hits = ref<BuybackProduct[]>([])
  const isLoading = ref(false)
  const error = ref<Error>()

  const { ALGOLIA_ID } = useRuntimeConfig().public
  const logger = useLogger()

  const { data, error: BuybackSearchConfigurationError } =
    getBuybackSearchConfiguartion(scope)

  function initAlgoliaIndex() {
    if (indexName.value && apiKey.value) {
      algoliaIndex.value = algoliasearch(ALGOLIA_ID, apiKey.value).initIndex(
        indexName.value,
      )
    }
  }

  async function search() {
    if (!algoliaIndex.value) return

    isLoading.value = true
    try {
      const searchResult = await algoliaIndex.value.search<AlgoliaHit>(
        query.value,
        {
          hitsPerPage: 5,
          page: 0,
        },
      )

      hits.value = searchResult.hits.map(algoliaHitToBuybackProduct)
    } catch (algoliaError) {
      error.value = algoliaError as Error
    } finally {
      isLoading.value = false
    }
  }

  const performSearch = options.debounce
    ? useDebounceFn(search, options.debounceTime)
    : search

  watchEffect(() => {
    if (typeof query.value === 'string' && algoliaIndex.value) {
      void performSearch()
    }
  })

  watchEffect(() => {
    if (data.value) {
      apiKey.value = data.value.apiKey
      indexName.value = data.value.indexName

      initAlgoliaIndex()

      void performSearch()
    }
  })

  watchEffect(() => {
    if (BuybackSearchConfigurationError.value) {
      error.value = BuybackSearchConfigurationError.value
    }
  })

  watchEffect(() => {
    if (error.value) {
      logger.error(SWAP_INFO_MESSAGE.SWAP_SEARCH, {
        error: error.value,
        owners: ['bot-squad-circularity-customer-front'],
      })
    }
  })

  return { query, hits, apiKey, indexName }
}
