<template>
  <RevCard class="!bg-surface-brand-hi flex flex-col p-16">
    <p class="body-2 mb-18 flex items-center gap-8">
      <IconCrossInCircle class="text-static-brand-mid" />
      <span>{{ model }}</span>
    </p>
    <h3 class="body-1-bold">
      {{ i18n(translations.title) }}
    </h3>
    <p class="body-2 mb-16">
      {{ i18n(translations.content) }}
    </p>

    <TradeInSearchBar
      v-if="withSearchBar"
      @default-item-selected="() => $emit('default-item-selected')"
      @item-selected="(item) => $emit('item-selected', item)"
    />

    <RevButton
      v-else
      data-qa="swap-scarab-button"
      full-width="always"
      variant="secondary"
      @click="$emit('sell-other')"
    >
      {{ i18n(translations.buttonSell) }}
    </RevButton>
  </RevCard>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { IconCrossInCircle } from '@ds/icons/IconCrossInCircle'

import type { BuybackProduct } from '~/scopes/buyback/composables/useBuybackSearch'

import TradeInSearchBar from '../TradeInSearchBar/TradeInSearchBar.vue'

import translations from './NoOfferV2.translations'

const i18n = useI18n()

withDefaults(
  defineProps<{
    model: string
    withSearchBar?: boolean
  }>(),
  { withSearchBar: false },
)

defineEmits<{
  (e: 'sell-other'): void
  (e: 'item-selected', value: BuybackProduct): void
  (e: 'default-item-selected'): void
}>()
</script>
