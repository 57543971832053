export default {
  delete: {
    id: 'accepted_offer_delete',
    defaultMessage: 'Delete',
  },
  titleUpper: {
    id: 'accepted_offer_title',
    defaultMessage: 'Congrats on your offer!',
  },
  titleLower: {
    id: 'accepted_offer_title_lower',
    defaultMessage: 'Your price offer:',
  },
  tradeinValue: {
    id: 'accepted_offer_tradein_value',
    defaultMessage: 'Trade-in value',
  },
  valueDecrease: {
    id: 'accepted_offer_description_decrease',
    defaultMessage: 'Best time to sell — value decreases over time',
  },
  circularity: {
    id: 'accepted_offer_description_circularity',
    defaultMessage: 'Keeps valuable resources in circulation',
  },
  money: {
    id: 'accepted_offer_description_money',
    defaultMessage: 'Highest offer out of 250 professional refurbishers',
  },
  shipping: {
    id: 'accepted_offer_description_shipping',
    defaultMessage: 'Free & insured shipping',
  },
  quality: {
    id: 'accepted_offer_description_quality',
    defaultMessage: 'Over 2 million successful trade-ins',
  },
}
