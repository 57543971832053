export default {
  title: {
    id: 'swap_confirmation_title',
    defaultMessage: 'Hurray, we found a buyback offer for your {device}',
  },
  priceHint: {
    id: 'swap_confirmation_price_hint',
    defaultMessage: 'Refunded after your buy',
  },
  firstItemTitle: {
    id: 'buyback_swap_offer_list_item_1_title',
    defaultMessage: 'Finish shopping',
  },
  firstItemDesc: {
    id: 'buyback_swap_offer_list_item_1_desc',
    defaultMessage:
      'Your trade-in value will appear in your cart once you add whatever you’d like to buy.',
  },
  secondItemTitle: {
    id: 'buyback_swap_offer_list_item_2_title',
    defaultMessage: 'Ship your item for free',
  },
  secondItemDesc: {
    id: 'buyback_swap_offer_list_item_2_desc',
    defaultMessage:
      'You’ll have 21 days to transfer your data to another device and ship your trade-in.',
  },
  thirdItemTitle: {
    id: 'buyback_swap_offer_list_item_3_title',
    defaultMessage: 'Enter your banking info to get paid',
  },
  thirdItemDesc: {
    id: 'buyback_swap_offer_list_item_3_desc',
    defaultMessage: 'Get cash money',
  },
}
