export default {
  titleModal: {
    id: 'view_offer_modal_title',
    defaultMessage: 'Trade-in offer',
  },
  continue: {
    id: 'view_offer_modal_continue',
    defaultMessage: 'Continue Shopping',
  },
}
