export default {
  ad: {
    id: 'swap_ad_v2',
    defaultMessage:
      'Want to get this for even less? Like {price}*? Trade in your old tech for cash.',
  },
  purposal: {
    id: 'swap_buyback_intro_journey_purposal_v2',
    defaultMessage:
      '*Price shown is calculated based on the average order values and may vary',
  },
  yes: {
    id: 'swap_buyback_purposal_yes',
    defaultMessage: 'Yes',
  },
  yesSubtitle: {
    id: 'swap_buyback_purposal_yes_subtitle',
    defaultMessage: 'Estimate the buyback offer >',
  },
  no: {
    id: 'swap_buyback_purposal_no',
    defaultMessage: 'No',
  },
  title: {
    id: 'swap_buyback_purposal_plp_title',
    defaultMessage: 'See how Trade-in works',
  },
  subTitle: {
    id: 'swap_buyback_purposal_plp_sub_title',
    defaultMessage: 'Add an item to your cart in order to trade-in.',
  },
  listBadgeTitle: {
    id: 'swap_buyback_purposal_plp_list_badge_title',
    defaultMessage: 'Get an offer',
  },
  listBadgeDescription: {
    id: 'swap_buyback_purposal_plp_list_badge_description',
    defaultMessage:
      'See an offer from a professional refurbisher in about 2 minutes.',
  },
  listPackageTitle: {
    id: 'swap_buyback_purposal_plp_list_package_title',
    defaultMessage: 'Ship your trade-in for free',
  },
  listPackageDescription: {
    id: 'swap_buyback_purposal_plp_list_package_description',
    defaultMessage: 'You’ll have 21 days to ship your item.',
  },
  listMoneyTitle: {
    id: 'swap_buyback_purposal_plp_list_money_title',
    defaultMessage: 'Get cash money',
  },
  listMoneyDescription: {
    id: 'swap_buyback_purposal_plp_list_money_description',
    defaultMessage:
      'Once your item is received and accepted, you’ll get a direct deposit within 5 business days.',
  },
  getOffer: {
    id: 'swap_buyback_purposal_plp_get_offer',
    defaultMessage: 'Get an offer',
  },
}
