import { type LocationQuery } from 'vue-router'

import { defineStore } from 'pinia'

export interface Swap {
  initialPayload?: LocationQuery
  showAddToCartModal: boolean
}

export const useSwapModalStore = defineStore('useSwapModalStore', {
  state: (): Swap => ({
    initialPayload: undefined,
    showAddToCartModal: true,
  }),
  actions: {
    setInitialPayload(newInitialPayload: LocationQuery) {
      this.initialPayload = newInitialPayload
    },
    resetInitialPayload() {
      this.initialPayload = undefined
    },
    setShowAddToCartModal(showAddToCartModal: boolean) {
      this.showAddToCartModal = showAddToCartModal
    },
  },
})
