export default {
  getSwapEstimation: {
    id: 'product_page_swap_cta',
    defaultMessage: `{ hasOffer, select,
      true {Re-value my item}
      other {Value my item}
    }`,
  },
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  swapTitle: {
    id: 'product_page_swap_title',
    defaultMessage: 'Do you have an item to trade-in?',
  },
  swapSubtitle: {
    id: 'product_page_swap_subtitle',
    defaultMessage: `{ hasSwapOffer, select,
      true {Get this {model, html} {price, html} by trading-in your old {itemToSwap, html}.}
      other {Get this {model, html} {price, html} by trading-in your old item.}
    }`,
  },
  swapPrice: {
    id: 'product_page_swap_price',
    defaultMessage: `{ isPriceForFree, select,
      true {for free}
      other {for {price} or less}
    }`,
  },
  swapTooltip: {
    id: 'product_page_swap_tooltip',
    defaultMessage:
      "Trading-in your device is easy. We'll ask you to describe its technical and cosmetic condition and then give you our best price for a trade-in. All you have to do is send it off free of charge, sit back, and get your money within 7 working days.",
  },
}
