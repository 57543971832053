export default {
  title: {
    id: 'buyback_swap_no_offer_v2_title',
    defaultMessage: 'Sorry, we didn’t find an offer.',
  },
  content: {
    id: 'buyback_swap_no_offer_v2_content',
    defaultMessage:
      'This can happen when your device is too old or too damaged. Why not try another another device?',
  },
  buttonSell: {
    id: 'buyback_swap_no_offer_v2_sell',
    defaultMessage: 'Sell another device',
  },
}
