<template>
  <section>
    <RevIllustration
      alt=""
      class="m-auto mb-32 mt-16 block"
      :height="120"
      src="/img/buyback/sad_coin.svg"
      :width="120"
    />
    <h3 class="heading-1 mb-16">
      {{ i18n(translations.title) }}
    </h3>
    <p class="body-1 mb-16">
      {{ i18n(translations.contentFirst) }}
    </p>
    <p class="body-1 mb-16">
      {{ i18n(translations.contentSecond) }}
    </p>
    <RevButton
      class="my-16"
      data-qa="swap-scarab-button"
      full-width="always"
      variant="secondary"
      @click="$emit('sell-other')"
    >
      {{ i18n(translations.buttonSell) }}
    </RevButton>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './NoOffer.translations'

const i18n = useI18n()

defineEmits<{
  (e: 'sell-other'): void
}>()
</script>
