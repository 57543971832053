export default {
  clearButtonAriaLabel: {
    id: 'swap_step_select_clear',
    defaultMessage: 'Clear',
  },
  swapSelectPlaceholder: {
    id: 'swap_step_select_placeholder',
    defaultMessage: 'Select brand and model',
  },
  swapSelectNoOptionsMessage: {
    id: 'swap_step_select_no_option',
    defaultMessage: 'No results available',
  },
  swapSelectLabelOptionDefault: {
    id: 'swap_step_select_label_option_default',
    defaultMessage: "My device isn't listed here",
  },
  swapSelectSuffixOption: {
    id: 'swap_step_select_suffix_option',
    defaultMessage: 'Up to {price}',
  },
}
