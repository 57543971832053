<template>
  <TheCatcherComponent>
    <EmbeddedSwapModal
      v-if="
        experiments['experiment.oneQuestionByStep'] === 'withOneQuestionByStep'
      "
      :datalayer-category
      :initial-payload
      :intro
      :modal-name
      :price
      :redirect-on-close-target
      :zone
      @confirmation="$emit('confirmation')"
      @continue="$emit('continue')"
    />

    <SwapModal
      v-else
      :datalayer-category
      :initial-payload
      :intro
      :modal-name
      :price
      :redirect-on-close-target
      :zone
      @close-with-offer="$emit('close-with-offer')"
      @confirmation="$emit('confirmation')"
      @continue="$emit('continue')"
    />
  </TheCatcherComponent>
</template>

<script setup lang="ts">
import { type LocationQuery } from 'vue-router'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import TheCatcherComponent from '~/scopes/buyback/components/TheCatcher/TheCatcherComponent.vue'

import EmbeddedSwapModal from '../EmbeddedSwapModal/EmbeddedSwapModal.vue'
import SwapModal from '../SwapModal/SwapModal.vue'

export interface SwapModalProps {
  modalName: string
  redirectOnCloseTarget?: string | null
  datalayerCategory: string
  zone: string
  price?: string
  intro?: 'atc' | 'plp'
  initialPayload?: LocationQuery
}

withDefaults(defineProps<SwapModalProps>(), {
  redirectOnCloseTarget: null,
  initialPayload: undefined,
  intro: undefined,
  price: '',
})

const experiments = useExperiments()

defineEmits(['close-with-offer', 'confirmation', 'continue'])
</script>
