<template>
  <section class="pb-32">
    <h3 class="heading-3 mb-16">
      {{ props.categories?.title }}
    </h3>

    <RevButtonBase
      v-for="option in props.categories?.options"
      :key="option?.value"
      class="bg-action-default-low shadow-short rounded-sm mt-8 flex w-full cursor-pointer items-center px-16 py-12 hover:bg-action-default-low"
      :data-qa="`category-${option.value}`"
      @click="$emit('next-step', { category: option.value })"
    >
      <RevIllustration
        v-if="option.icon?.light && variant === 'embedded'"
        alt=""
        class="mr-12"
        :height="48"
        :src="option.icon.light"
        :width="48"
      />

      <div class="grow text-left">
        <p class="body-1">
          {{ option.label }}
        </p>
      </div>

      <p
        v-if="option.maxPriceEstimate && variant === 'embedded'"
        class="text-static-default-low body-2"
      >
        {{
          i18n(translations.upToPrice, {
            price: i18n.price(option.maxPriceEstimate, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })
        }}
      </p>
      <RevIllustration
        v-if="option.icon?.light && variant === 'default'"
        alt=""
        :height="48"
        :src="option.icon.light"
        :width="48"
      />
    </RevButtonBase>
  </section>
</template>

<script lang="ts" setup>
import type { Question } from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Categories.translations'

interface CategoriesProps {
  categories: Question | Record<string, never>
  variant?: 'embedded' | 'default'
}

export interface CategoryPayload {
  category: string
}

const props = withDefaults(defineProps<CategoriesProps>(), {
  categories: () => ({}),
  variant: 'default',
})

defineEmits<{
  (e: 'next-step', category: CategoryPayload): void
}>()

const i18n = useI18n()
</script>
