export default {
  title: {
    id: 'buyback_swap_v2_modal_title',
    defaultMessage: '{stepNumber} of {totalStep}: {label}',
  },
  categoriesTitle: {
    id: 'buyback_swap_v2_modal_categories_title',
    defaultMessage: 'Sell your old tech',
  },
  noOfferTitle: {
    id: 'buyback_swap_v2_modal_no_offer_title',
    defaultMessage: 'No offers',
  },
  offerTitle: {
    id: 'buyback_swap_v2_modal_offer_title',
    defaultMessage: 'Your trade-in offer',
  },
  goToCartButtonLabel: {
    id: 'buyback_swap_v2_modal_go_to_cart_button_label',
    defaultMessage: 'Skip trade-in',
  },
  introTitle: {
    id: 'swap_swap_v2_intro_modal_title',
    defaultMessage: 'Got old tech ?',
  },
  acceptOffer: {
    id: 'swap_confirmation_button_yes',
    defaultMessage: 'Accept',
  },
  declineOffer: {
    id: 'swap_confirmation_button_no',
    defaultMessage: 'No thanks',
  },
  continue: {
    id: 'swap_confirmation_continue',
    defaultMessage: 'Continue',
  },
  loadingOfferText: {
    id: 'buyback_swap_loading_offer_text',
    defaultMessage:
      'Finding you the highest offer out of more than 250 professional refurbishers!',
  },
  noToCart: {
    id: 'swap_buyback_purposal_no_subtitle',
    defaultMessage: 'Go to cart',
  },
  getOffer: {
    id: 'swap_buyback_purposal_plp_get_offer',
    defaultMessage: 'Get an offer',
  },
}
