<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    data-test="modal-swap-offer"
    :has-back-button="false"
    :name="modalName"
    :title="i18n(translations.titleModal)"
    @open="handleModalOpen"
  >
    <template #body>
      <AcceptedOffer
        v-if="buybackOffer"
        :name="buybackOffer.product.shortTitle"
        :price="buybackOffer.listing.price"
        :tier="buybackOffer.tier"
        @remove="deleteOffer"
      />
    </template>
    <template #footer>
      <RevButton full-width="always" variant="primary" @click="continueFlow">
        {{ i18n(translations.continue) }}
      </RevButton>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevDrawer } from '@ds/components/Drawer'
import { closeModal } from '@ds/components/ModalBase'

import AcceptedOffer from '../AcceptedOffer/AcceptedOffer.vue'

import translations from './ViewOfferModal.translations'

const i18n = useI18n()
const { deleteBuybackOffer, buybackOffer } = useBuybackOffer()
const tracking = useTracking()

const props = defineProps<{
  modalName: string
  trackingZone: string
}>()

function deleteOffer() {
  void deleteBuybackOffer()
  closeModal(props.modalName)

  tracking.trackClick({
    zone: props.trackingZone,
    name: 'delete_tradein',
  })
}

function continueFlow() {
  closeModal(props.modalName)
}

function handleModalOpen() {
  tracking.trackModal({
    name: props.trackingZone,
    status: 'opened',
  })
}
</script>
